$c1: #000;
$c2: #fff;
$c3: #D6CDB9;
$c4: #404040;
$c5: #F9F4EA;
$c6: #3F3F3F;
$f2: 'Noto Sans', sans-serif;
$f1: 'Moonstone', sans-serif;
$breakTablet: 1150px;
$breakMobile: 320px;
$layoutWidth: 163.7rem;
$boxShadow: 0 0 0.1rem rgba(34, 25, 25, 0.4);

@font-face {
	font-family: 'Moonstone';
	font-style: normal;
	font-weight: 400;
	src: local('Moonstone'), url("../fonts/Moonstone-Regular.woff") format('woff2');
}


:root {
	font-size: 62.5%;
}

body {
	min-height: 100vh;
	background: $c5;
}

* {
	&:focus {
		outline: 0.3rem dotted $c1;
		outline-offset: 0.3rem;
	}
}
/*RESET*/

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
	font-family: $f2;
	font-size: 1.6rem;
	line-height: 1.875;
	color: $c4;
	box-sizing: border-box;
}

blockquote {
	font-size: 8.2rem;
	font-family: $f1;
	line-height: 12rem;
	text-align: center;
	@media(max-width: $breakTablet){
		font-size: 4.1rem;
		line-height: 6rem;
	}
}

abbr {
	text-decoration: none;
}



ol.table,
ul.table {
	width: 100%;
	list-style: none;
	padding: 0;

	@media(max-width: $breakTablet) {
		overflow-x: scroll;
		position: relative;
		border: 0.1rem solid #ccc;

		& > li {

			& > ul {
				li:first-child {
					position: sticky;
					left: 0;
					background: #fff;
					border-right: 0.1rem solid #ccc;
					max-width: 13.5rem;
					padding: 1rem;
				}
				li {
					background: #fff;
					width: 100% !important;
					min-width: 12rem;
				}
				li:nth-child(2) {
					padding-left: 2rem;
				}
				li:last-child {
					padding-right: 2rem;
				}
			}
		}
	}

	li.header {
		font-weight: bold;
	}

	&.w25 {
		ul > li {
			width: 25%;
		}
	}
	&>li{
		border-bottom: .1rem solid #ccc;
		@media(max-width: $breakTablet){
			border-bottom: 0;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		width: 100%;
		justify-content: space-between;

		li {
			padding: 1rem 0;
			@media(max-width: $breakTablet){
				border-bottom: .1rem solid #ccc;
			}
		}

		li:last-child {
			text-align: right;
		}

	}
}

.content-video-list{
	
	ul{
		padding-left: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		li{
			margin-top: 0;
			margin-bottom: 2.4rem;
			margin-right: 1.6rem;
			width: calc(33.333333333333333% - 1.6rem);
			@media(max-width: $breakTablet){
				width: 100%;
				margin-right: 0;
			}
			
			video{
				width: 100%;
			}

		}
	}
}

button,
.button,
.cookiebar__button {
	border-radius: 0.3rem;
	font-size: 1.6rem;
	padding: 0.5rem 1.6rem;
	background: $c4;
	color: #fff;
	border: 0.1rem solid $c1;

	&:focus,
	&:hover {
		color: $c4;
		background: #fff;
	}
}

h1 {
	font-size: 4.8rem;
	@media(max-width: $breakTablet){
		font-size: 3.2rem;
	}
}

h2 {
	font-size: 2.4rem;

	* {
		font-size: 2.4rem !important;
	}
}

h3 {
	font-size: 2.2rem;

	* {
		font-size: 2.2rem !important;
	}
	@media(max-width: $breakTablet){
		font-size: 2rem;
		* {
			font-size: 2rem !important;
		}
	}
}

h4 {
	font-size: 2rem;
	@media(max-width: $breakTablet){
		font-size: 1.8rem !important;
	}
}

h5 {
	font-size: 1.8rem;
	@media(max-width: $breakTablet){
		font-size: 1.6rem !important;
	}
}

h6 {
	font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	margin-top: 1.6rem;
	line-height: 1.1;

	& + div:not(.rte) > p {
		margin-top: 0.5rem;
	}
}

ul {
	margin-top: 1.6rem;
	padding-left: 1.4rem;
	list-style-position: outside;
}

ol {
	margin-top: 1.6rem;
	padding-left: 1.8rem;
	list-style-type: decimal;
	list-style-position: outside;
}

b,
strong {
	font-weight: bold;
}

img {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 0.5rem;
}

time {
	display: inline-block;
}

a {
	text-decoration: none;
}

p {
	margin-top: 1.6rem;
}

main a {
	//border-bottom: 0.1rem solid $c3;
	color: $c4;
}

table {
	border-collapse: collapse;
	width: 100%;
	@media(max-width: $breakTablet) {
		border: 0.0625rem solid $c1;
	}

	* {
		box-sizing: border-box;
	}

	caption {
		margin-bottom: 1.6rem;
		font-size: 1.4rem;
		text-align: left;
		@media(max-width: $breakTablet) {
			margin-bottom: 0.25rem;
		}
	}

	td,
	th {
		text-align: left;
		padding: 0.5rem;
	}

	thead {
		font-weight: bold;

		th {
			border-bottom: 0.0625rem solid $c1;
		}
		@media(max-width: $breakTablet) {
			display: none;
		}
	}

	tfoot {
		td {
			border-top: 0.0625rem solid $c1;
		}

		td,
		th {
			@media(max-width: $breakTablet) {
				display: block;
				width: 100%;
				text-align: left;

				&:empty {
					display: none;
				}

				&[data-label]:before {
					width: 100%;
					display: block;
					content: attr(data-label);
					float: left;
					font-weight: bold;
				}
			}
		}
	}

	tbody {
		th {
			font-weight: bold;
		}

		td,
		th {
			@media(max-width: $breakTablet) {
				display: block;
				width: 100%;
				text-align: left;

				&:empty {
					display: none;
				}

				&[data-label]:before {
					width: 100%;
					display: block;
					content: attr(data-label);
					float: left;
					font-weight: bold;
				}
			}
		}

		tr:nth-child(even) {
			td,
			th {
				background: #ccc;
			}
		}
	}
}

[aria-expanded="false"] {
	& + ul {
		//display: none;
	}
}

[aria-expanded="true"] {
	& + ul {
		display: flex;
		position: absolute;
		z-index: 999;
	}

	& + div {
		display: block;
	}
}

.invisible {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;

	&.article {
		position: relative;
		top: -8.0625rem;
		@media(max-width: $breakTablet) {
			top: -5rem;
		}
	}

	&:focus {
		position: fixed;
		left: 50%;
		top: 1.6rem;
		width: auto;
		height: auto;
		background: $c1;
		color: #fff;
		padding: 1.6rem;
		font-weight: 500;
		box-shadow: 0 0 1.6rem rgba(0,0,0,0.5);
		z-index: 999;
	}
}

#footer {
	clip-path: url(#footer-curve);
	@media(max-width: $breakTablet) {
		clip-path: url("#footer-curve-mobile");
	}
	.inside {
		margin: 0 auto;
		padding: 0 1.6rem;
		max-width: $layoutWidth;
	}

	h2 {
		font-size: 1.6rem;
	}

	h3 {
		font-size: 2.2rem;
	}
}

#header {

	a.logo {
		img {
			max-width: 25rem;
			width: 100%;
			@media(max-width: $breakMobile) {
				max-width: 15rem;
			}
		}
	}

	@media(max-width: $breakTablet){
		height: 6.4rem;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 999;
	}

	.inside {

		@media(max-width: $breakTablet){
			height: 6.4rem;
			display: flex;
			background: $c3;
		}

		.inner {
			display: flex;
			margin: 0 auto;
			padding: 0 2.4rem;
			max-width: $layoutWidth;
			justify-content: space-between;
			align-items: center;
			@media(max-width: $breakTablet) {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate3d(-50%,0,0);
			}
		}

		#quicklinks {
			align-items: center;
			height: 5rem;
			background: #D6CDB9;
			padding: 0 2.4rem;
			@media(max-width: $breakTablet) {
				display: none;
			}

			img {
				max-width: 2.3rem;
				margin-right: 0.5rem;
			}

			a {
				display: inline-flex;
				align-items: center;
				margin-right: 1rem;

				&:last-child {
					margin-right: 0;
				}
			}

			ul {
				height: 5rem;
				margin: 0;
				list-style: none;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;

				li {
					display: flex;
					align-items: center;
					padding: 0 1.6rem;
					height: 5rem;
					border-right: 0.1rem solid #fff;
				}
			}
		}

		#mainnavi {
			margin-top: 0.9rem;
			justify-self: start;

			li{
				&.suche{
					&:after{
						content: '';
						background: url(../img/lupe.svg);
						width: 20px;
						height: 20px;
						display: inline-block;
						position: absolute;
						right: -26px;
						margin-top: 4px; 
					}
				}
			}
		}

		.trigger {
			display: none;
			margin: 1rem;
			width: 2.4rem;
			align-self: center;
			img{
				border-radius: 0;
			}
			@media(max-width: $breakTablet) {
				display: block;
			}
		}

		.logo {
			width: 25rem;
			margin: 2.4rem 2.4rem 0 0;
			@media(max-width: $breakTablet) {
				margin: -.5rem 0 0 0;
				width: 15rem;
			}
		}
	}
}

#container {
	min-height: calc(100vh - 30rem);
	@media(max-width: $breakTablet){
		margin-top: 6rem;
	}
	li,
	p,
	label {
		a {
			border-bottom: .1rem solid #404040;
		}
	}
}

#footer {
	background: #D6CDB9;
	padding: 5rem 0;
	margin-top: 5rem;

	a {
		color: $c4;
	}
}

#banner {
	position: relative;

	@media(max-width: $breakTablet) {
		margin-top: 6.4rem;
	}

	.mod_rocksolid_slider{
		clip-path: url("#banner-curve");
	}

	img {
		border-radius: 0 !important;
		@media(max-width: $breakTablet) {
			//clip-path: polygon(0 1rem, 100% 0, 100% 100%, 0 100%);
		}
	}
}

.mod_navigation {

	@media(max-width: $breakTablet) {
		display: none;
	}

	button {
		display: inline-block;
		height: 100%;
		border: 0;
		background: none;
		padding: 0 0 0 0.25rem;
		width: 1.6rem;
		background: url("../img/chevron-down.svg") no-repeat center center;
	}

	a.trail{
		font-weight: bold;
	}

	ul {
		list-style: none;

		li {
			display: flex;

			span,
			strong {
				white-space: nowrap;
			}
		}
	}

	li.submenu:hover {
		& > ul {
			display: flex;
		}

		& > div {
			display: block;
		}
	}

	ul.level_1 {
		display: flex;
		flex-wrap: wrap;

		&>ul {
			margin-top: 2.6rem;
		}

		& > li {
			margin-left: 2.4rem;
			position: relative;
		}

		ul {
			position: absolute;
			z-index: 999;
		}
	}

	ul.level_2 {
		min-width: 15rem;
		margin: 0 auto;
		margin-top: 2.4rem;
		background: $c6;
		padding: 0;
		flex-direction: column;

		li {
			padding: 1.6rem;
		}

		span,
		strong {
			color: $c3;
		}
	}

	ul.level_3{
		height: 100%;
		top: 0;
		left: 0;
		transform: translate3d(100%,0,0);
		width: 100%;
		background: $c3;
		margin: 0;
		flex-direction: column;
		strong,
		span{
			color: $c6;
		}
	}

	ul.level_4{
		height: 100%;
		top: 0;
		left: 0;
		transform: translate3d(100%,0,0);
		width: 100%;
		background: $c5;
		margin: 0;
		flex-direction: column;
		strong,
		span{
			color: $c6;
		}
	}

}

#main {

	.mod_breadcrumb {
		margin: 0 auto;
		max-width: $layoutWidth;
		padding: 0 2.4rem;
		@media(max-width: $breakTablet){
			display: none;
		}
	}

	.mod_article {
		& > .content-headline,
		& > div,
		& > section {
			max-width: 111.7rem;
			margin: 6rem auto 0;
			padding: 0 2.4rem;
		}

		& > .content-headline + div {
			margin-top: 2.4rem;
		}

		& > .content-text + .course-list{
			margin-top: 0;
		}

		.course-list + h2 {
			margin-top: 5rem;
		}

		& > section + section {
			margin-top: 0;
		}

		// first child is the breadcrumb navigation
		&:nth-child(2) {
			.jumbo:first-child {
				margin-top: 3rem;
			}
		}

		& > .jumbo {
			max-width: 100%;
			padding: 0 0 5rem;

			&.beige {
				background: $c5;
			}

			&.brown {
				background: $c3;
			}

			& > .inside {
				margin: 0 auto;
				padding: 2.4rem 2.4rem 0;
				max-width: 163.7rem;
			}
		}
	}
}

.rs-columns {
	margin-top: 1.6rem;

	& > h1,
	& > h2,
	& > h3,
	& > h4,
	& > h5,
	& > h6 {
		width: 100%;
		margin-bottom: 1rem;
	}

	* > div {
		margin-top: 0 !important;
	}

	&.reduced-margins {
		margin-top: 1.6rem !important;
	}

	&.reduced-margins .rs-column:not(.-large-first-row) {
		margin-top: 2.5%;
	}
}

.mod_newsarchive,
.mod_newslist {
	display: flex;
	flex-wrap: wrap;
	margin-top: 1.6rem;

	&.news_latest {
		margin-top: 0;
	}

	.layout_latest {
		display: flex;
		flex-direction: column;
		box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
		border-radius: 0.5rem;

		&:last-child {
			margin-right: 0;
		}

		p.info{
			margin: 0 0 1rem 0;
			font-size: 1.4rem;
			time{
				font-size: 1.4rem;
			}
		}

		img {
			border-radius: 0.5rem 0.5rem 0 0;
		}

		& > .txt {
			flex-grow: 1;
			border-radius: 0 0 0.5rem 0.5rem;
			padding: 3rem;
		}

		.more {
			a {
				padding-bottom: 0.5rem;
				border-bottom: 0.2rem solid #404040;
			}
		}
	}

	.layout_latest_left {
		width: 100%;
		display: flex;
		margin-top: 1.6rem;

		@media(max-width: $breakTablet){
			margin-top: 2.4rem;
			flex-direction: column;
		}

		h2{
			margin-top: 0;
		}

		p.info{
			margin-top: 0;
			font-size: 1.4rem;
			time{
				font-size: 1.4rem;
			}
		}

		figure {
			max-width: 36rem;
			width: 100%;
			img{
				width: 100%;
			}
			@media(max-width: $breakTablet){
				max-width: 100%;
			}
		}

		&>div {
			margin-left: 1.6rem;
			flex-grow: 1;
			width: 100%;
			@media(max-width: $breakTablet){
				margin-left: 0;
			}
		}
	}
}

.content-text{
	h1:first-child{
		margin-top: 0;
	}
}

.mod_newsmenu {
	display: flex;
	padding: 1.6rem 0;

	& + .content-text{
		margin-top: 0 !important;
	}


	h2 {
		margin: 0 0.8rem 0 0;
		font-size: 1.6rem;
		line-height: 1.875;
	}

	ul {
		display: flex;
		margin: 0;
		padding-left: 0;
		list-style: none;

		li {
			margin-right: 0.8rem;
		}

		a,
		strong {
			font-size: 1.6rem;
		}
	}
}

.mod_booknav,
.pagination {
	padding: 1rem 0;
	ul {
		display: flex;
		list-style: none;
		padding-left: 0;
		margin-top: 0;

		li {
			padding: 0 1rem 0 0;

			a {
				display: inline-block;
			}
		}
	}
}

.mod_booknav{
	li{
		&.previous a{
			&:before{
				content: "← "
			}
		}
		&.next a{
			&:after{
				content: " →"
			}
		}
	}
}

.mod_breadcrumb {
	display: flex;

	* {
		font-size: 1.4rem;
	}

	ul {
		display: flex;
		list-style: none;
		padding-left: 0.8rem;

		li {
			margin-right: 1rem;

			&:after {
				margin-left: 1rem;
				content: "›";
			}

			&:last-child {
				&:after {
					content: none;
				}
			}
		}
	}
}

.mod_faqpage {
	section {
		margin-top: 2.4rem;
	}
	article{
		margin-top: 4.8rem;
		&.first{
			margin-top: 0;
		}
	}
}


.rsce_person {
	position: relative;
	height: 100%;
	border-radius: 0.3rem;
	box-shadow: 0 0 0.3rem #ccc;
	background: $c5;
	display: flex;
	flex-direction: column;

	img {
		max-width: 100%;
		border-radius: 0.3rem 0.3rem 0 0;
	}

	h2 {
		font-size: 2.4rem;
		a{
			line-height: 1.1;
		}
	}

	& > div {
		flex: 1 0 auto;
		padding: 1.6rem;
		display: flex;
		flex-direction: column;

		.text {
			flex-grow: 1;
		}

		.lnk {
			align-self: flex-start;
			display: inline-block;
			margin-top: 1rem;
			border-bottom: 0.2rem solid #404040;
		}
	}
}

.slider_kurse {
	position: relative;
	h2 {
		margin: 0;
	}
	.slide{
		width: 100%;
		height: 100%;
		@media(max-width: $breakTablet){
			display: flex;
			flex-direction: column-reverse;
		}
	}
	figure{
		padding-left: 10%;
		@media(max-width: $breakTablet){
			padding-left: 0;
			img{
				border-radius: .5rem .5rem 0 0;
			}
		}
	}
	.nfo{
		background: $c3;
		padding: 2rem;
		width: 60%;
		position: absolute;
		top: 50%;
		transform: translate3d(0,-50%,0);

		@media(max-width: $breakTablet){
			width: 100%;
			position: static;
			transform: none;
		}
	}
}

.slider_kurse_standbild{
	position: relative;
	@media(max-width: $breakTablet){
		display: flex;
		flex-direction: column-reverse;
	}

	h2{
		margin: 0;
	}
	.inner{
		position: relative;
		z-index: 99;
		background: $c3;
		padding: 2rem;
		width: 100%;
		max-width: 27rem;
		transform: translate3d(0,3rem,0);
		@media(max-width: $breakTablet){
			position: static;
			max-width: 100%;
			transform: none;
		}
	}
	img{
		position: absolute;
		top: 0;
		right: 0;
		width: 75%;
		@media(max-width: $breakTablet){
			position: static;
			width: 100%;
		}
	}
}

.course-list {
	margin-bottom: 3rem;
	ol.table {
		ul {
			li {
				width: 12.5%;

				&:nth-child(1) {
					width: 15.625%;
				}

				&:nth-child(2) {
					width: 13.625%;
				}

				&:nth-child(5) {
					width: 5%;
				}

				&:nth-child(7) {
					width: 15.625%;
				}

				&:last-child {
					text-align: right;
				}
			}
		}
	}
}

.course-list.course_list_permanent{
	ol.table{
		ul li:nth-child(5){
			width: 15.625%;
		}
	}
}

.course-reader {
	h2 {
		margin-top: 5rem;
	}
}


.ce_accordion,
.content-accordion {
	margin-top: 1.6rem;

	.ui-accordion-header {
		padding: 1.6rem;
		border: 0.0625rem solid $c3;
		border-top: 0.0625rem solid $c2;
		border-left: none;
		border-right: none;
		background: $c3;
		color: $c2;
	}

	.ui-accordion-content {
		padding: 1.6rem;
		border-right: 0.0625rem solid $c3;
		border-left: 0.0625rem solid $c3;
		border-bottom: 0.0625rem solid $c3;

		*:first-child {
			margin-top: 0 !important;
		}
	}
}

.ce_accordion + .ce_accordion,
.ce_accordion + .content-accordion,
.content-accordion + .ce_accordion,
.content-accordion + .content-accordion {
	margin-top: 0;
}

.rsce_imgdeko {
	position: relative;

	img {
		max-width: 82%;
		margin-left: 18%;
	}

	&:before {
		content: '';
		background: $c3;
		width: 100%;
		height: calc(100% - 8.4rem);
		z-index: -1;
		top: 3.7rem;
		position: absolute;
	}
}

.content-hyperlink {
	position: relative;

	.hyperlink_img {
		img {
			border-radius: 0.3rem 0.3rem 0 0;
		}
	}

	figcaption {
		display: block;
		font-family: $f1;
		font-size: 8.2rem !important;
		line-height: 1;
		width: 100%;
		color: $c2;
		font-size: 2.4rem;
		text-align: center;
		padding: 2rem 1rem;
		border-radius: 0 0 0.3rem 0.3rem;
		bottom: 6rem;
		position: absolute;
		pointer-events: none;

		&:after {
			content: '';
			display: inline-block;
			width: 10rem;
			height: 0.7rem;
			background: #fff;
			position: absolute;
			bottom: 0.5rem;
			left: 50%;
			transform: translate3d(-50%,0,0);
		}
	}

	&.btn a{
		margin-top: 1rem;
		background: $c3;
		width: 100%;
		display: block;
		text-align: center;
		padding: .5rem;
		border-radius: .5rem;
	}
}

.slider-control {
	display: none;
}

.mod_sitemap {
	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;

		li.letter {
			padding-right: 1.6rem;
			width: calc(20% - 1.6rem);
			font-weight: 700;
			font-size: 3.2rem;
			margin: 2.4rem 0 0;
			background: $c5;

			@media(max-width: $breakTablet){
				width: calc(33.333333333333333% - 1.6rem);
			}
			@media(max-width: $breakMobile){
				width: 100%;
			}

			&:nth-child(-n+5) {
				margin: 0;
			}

			ul {
				margin: 0;
				display: block;
				padding: 0;
			}
		}
	}
}

.rsce_localbusiness {
	time {
		display: block;
	}
}

.rsce-banner {
	position: absolute;
	bottom: 8rem;
	text-align: center;
	width: 100%;

	@media(max-width: $breakTablet){
		display: none;
	}

	.fake-headline {
		line-height: 1.5;
		font-family: $f1;
		font-size: 12rem;
		color: $c5;
	}

	ul {
		list-style: none;
		display: flex;
		justify-content: center;

		li {
			padding: 0 1.6rem 0 0;
		}

		a {
			border-radius: 0.5rem;
			padding: 0.6rem 4rem;
			color: $c5;
			border: 0.3rem solid $c5;
			font-size: 3.8rem;
			background: rgba(214, 205, 185, .9);
			backdrop-filter: blur(0.8);
		}
	}
}

.listinline{
	ul{

		margin-top: 0;
		padding-left: 0;

		li{
			display: inline-block;
			margin-right: .5rem;
			&::before{
				content: "• ";
			}
		}
	}
	ul + ul{
		margin-top: 0;
	}
}

.rsce_box {
	margin-top: 4.8rem;
	background: $c5;
	border-radius: 0.5rem;
	overflow: hidden;
	height: 100%;

	img {
		border-radius: 0.5rem 0.5rem 0 0;
	}

	.txt {
		padding: 3rem 3.4rem;
		@media(max-width: $breakTablet){
			padding: 2rem;
		}
		&.listinline{
			padding: 0;
		}
	}

	.btn {
		padding-bottom: 0.5rem;
		font-size: 1.8rem;
		border-width: .2rem !important;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 0;
		font-size: 2.2rem;
		*{
			font-size: 2.2rem !important;
		}
	}
}

.course-registration{
	.lead{
		font-size: 2.4rem;
		strong{
			font-size: 2.4rem;
		}
	}
	label.required{
		& + ul{
		list-style: none;
		padding-left: 0;
		font-weight: bold;
		margin-top: 0;
			li{
				color: red;
			}
		}
	}
}

form#registration-form{
	section{
		display: none;
		margin-top: 1.6rem;
		background: $c3;
		padding: 1.6rem;
		&#section-person2{
			border-left: 1.6rem solid $c5;
			@media(max-width: $breakTablet){
				border-left: 0;
			}
		}
		&:first-child{
			display: block;
		}
		&.hide{
			display: none;
		}
		h2{
			margin-top: 0;
			&+label,
			&+div,
			&+textarea{
				margin-top: 1.6rem;
			}
		}
		label{
			display: block;
		}
		#type,
		#referral,
		#person1_gender,
		#person2_gender,
		&#section-newsletter,
		&#section-terms{
			label{
				display: inline;
			}
		}
	}

	div.persons{
		display: none;
		&>section{
			flex-grow: 1;
		}
		&.shown{
			display: flex;
			@media(max-width: $breakTablet){
				flex-direction: column;
			}
			section.show,
			& ~ section{
				display: block;
			}
		}
		#person1_dateOfBirth,
		#person2_dateOfBirth{
			display: flex;
			justify-content: space-between;
			select{
				width: 32%;
			}
		}
	}
	#referral{
		label:after{
			content: '';
			display: block;
		}
	}
}

.content-forgot-password{
	margin-top: 1.6rem !important;
	#form_email{
		max-width: 35rem;
	}

	.confirmation {
		color: #03a203;
	}
}

form#registration-form,
.content-form,
.content-forgot-password,
#mc_embed_signup,
.mod_login{
	input,
	select,
	textarea {
		width: 100%;
		display: block;
		background: $c2;
		border: 0.1rem solid $c4;
		padding: 0.5rem;
		box-sizing: border-box;
	}

	input[type="checkbox"],
	input[type="radio"]{
		display: inline-block;
		width: auto;
	}
	
	button[type="submit"]{
		margin-top: .8rem;

		&:disabled, &:disabled:hover {
			background-image: none;
			border: none;
			box-shadow: none;
			cursor: not-allowed;
			opacity: .4;
			pointer-events: none;
		}
	}
	.widget-submit{
		padding-top: 1rem;
	}

	.checkbox_container {
		span {
			display: flex;
			flex-direction: row;

			input[type="checkbox"] {
				max-width: 1.6rem;
				margin: 0.8rem 1rem 0 0;
			}
		}
	}

	.error {
		color: red;
	}
}

#mc_embed_signup{
	margin-top: 1.6rem !important;
	input[type="submit"]{
		margin-top:.5rem;
		width: auto;
		background: $c4;
		&:hover,
		&:focus{
			background: #fff;
		}
	}
}

.content-gallery {
	ul {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		list-style: none;
	}

	.gutter-sizer{
		width: 1.6rem !important;
	}

	li {
		list-style-image: none;

		figure {
			margin: 0 0 1.6rem;
			overflow: hidden;
			@media(max-width: $breakTablet) {
				margin-bottom: 1.6rem;
			}
			@media(max-width: $breakMobile) {
				margin-bottom: 0.75rem;
			}

			img{
				transition: transform .2s;
			}

			&:focus,
			&:hover {
				img {
					transform: scale3d(1.2,1.2,1.2);
				}
			}
		}
	}

	&--cols-1 {
		li {
			width: 100%;
		}
	}

	&--cols-2 {
		li {
			width: calc(50% - 1.6rem);
		}
		@media(max-width: $breakMobile) {
			width: 100%;
		}
	}

	&--cols-3 {
		li {
			width: calc(33.333333333333333% - 1.1rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols-4 {
		li {
			width: calc(25% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols-5 {
		li {
			width: calc(20% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols-6 {
		li {
			width: calc(16.66666666666667% - 1.6rem);
			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols-7 {
		li {
			width: calc(14.28571428571429% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols-8 {
		li {
			width: calc(12% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols-9 {
		li {
			width: calc(11.11111111111111% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols--10 {
		li {
			width: calc(10% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols--11 {
		li {
			width: calc(9.090909090909091% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}

	&--cols-12 {
		li {
			width: calc(8.333333333333333% - 1.6rem);

			@media(max-width: $breakTablet) {
				width: calc(50% - 1.6rem);
			}
			@media(max-width: $breakMobile) {
				width: 100%;
			}
		}
	}
}



.cookiebar{
	background: $c3;
	padding: 2rem 1.5rem;
	box-shadow: 0 0 .5rem rgba(0,0,0,.2);
}
.cookiebar--active{
	flex-wrap: nowrap;
	.cookiebar__message{
		text-align: left;
		margin-right: 0;
	}
	.cookiebar__link{
		color: $c4;
	}
}

.mod_eventreader{
	p.location{
		margin-top: 0;
	}
}

.mod_eventlist{
	time{
		display: inline;
	}

}

.content-player,
.ce_player {

	figure {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
	}

	figure video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	& + .rsce-playlist{
		margin-top: 0 !important;
	}
}

.rsce-playlist{
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
		li{
			border-bottom: .1rem solid #fff;
			button{
				width: 100%;
				border-radius: 0;
				border: none;
				text-align: left;
				padding: 1.6rem 1rem;
			}
		}
	}
}

#mc_embed_signup_scroll{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.mc-field-group{
		width: 100%;
		&.w50{
			width: 48%;
		}
	}

}

.content-youtube{
	figure{
		position: relative; 
		padding-bottom: 56.25%; 
		height: 0; 
		overflow: hidden; 
		max-width: 100%;
	}
	iframe{
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
	}
}


.mm-panels{
	.mm-listview{
		
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		li{
			width: 100%;

			&.quicklink{
				&:after{
					content: unset;
				}
				margin-top: 3rem;
				margin-left: 2rem;
				width: 3.2rem;
				a{
					padding: 0;
				}
				img{
					width: 3.2rem;
				}
			}
		}
	}

	.member.mm-listitem__text,
	.goodies.mm-listitem__text{
		display:flex;
		img{
			max-width: 2.4rem;
			margin-right: 1rem;
		}
	}
}

.mod_search{
	max-width: 900px;
	.formbody{
		display:flex;
		
		.widget-text{
			flex-grow: 1;
		}
		input{
			width: 99%;
			padding: 16px 16px;
			font-size: 2.4rem;
		}
		button{
			font-size: 2.4rem;
			padding: 16px 16px;
		}
	}

	.search_default{
		background: #D6CDB9;
		padding: 16px;
		margin-top: 16px;
		
		h3{
			margin-top: 0;
		}
		.url{
			a{
				word-wrap: break-word;
			}
		}
	}
}
