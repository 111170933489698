/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/

#colorbox,
#cboxOverlay,
#cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

#cboxWrapper {
	max-width: none;
}

#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
	clear: left;
}

#cboxContent {
	position: relative;
}

#cboxLoadedContent {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

#cboxTitle {
	margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
	cursor: pointer;
}

.cboxPhoto {
	float: left;
	margin: auto;
	border: 0;
	display: block;
	max-width: none;
	-ms-interpolation-mode: bicubic;
}

.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0;
	padding: 0;
	margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/

#cboxOverlay {
	background: #fff;
	opacity: 0.9;
	filter: alpha(opacity=90);
}

#colorbox {
	outline: 0;
}

#cboxContent {
	margin-top: 20px;
	background: #000;
}

.cboxIframe {
	background: #fff;
}

#cboxError {
	padding: 50px;
	border: 1px solid #ccc;
}

#cboxLoadedContent {
	border: 5px solid #D6CDB9;
	background: #fff;
}

#cboxTitle {
	position: absolute;
	top: -20px;
	left: 0;
	color: #D6CDB9;
}

#cboxCurrent {
	position: absolute;
	top: -25px;
	right: 0px;
	color: #D6CDB9;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
/*
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	width: auto;
	background: none;
}
*/
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
	outline: 0;
}

#cboxSlideshow {
	position: absolute;
	top: -20px;
	right: 90px;
	color: #fff;
}

/*
#cboxPrevious,
#cboxNext,
#cboxClose{
	display: none;
}
*/



#cboxClose{
	border-radius: 0;
	background: url("../img/colorbox.png") no-repeat top center;
}
#cboxNext{
	overflow:hidden;
	border-radius: 0;
	background: url("../img/colorbox.png") no-repeat top right;
}
#cboxPrevious{
	border-radius: 0;
	background: url("../img/colorbox.png") no-repeat top left;
}
